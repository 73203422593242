import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import ExternalLink from "../components/blog/ExternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const BlogPost = ({location}) =>{
    return <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="Back To School Clothes Shopping Made Easy"
            description="Tired of not knowing what kids' clothes to get every new school year? Here's a neat little printable to save you the headache."
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                Back To School Clothes Shopping Made Easy
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/Back-To-School-Clothes-Shopping.jpg" alt="Back To School Clothes Shopping Made Easy Printable List And Freebies"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@element5digital?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Element5 Digital</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/back-to-school?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            Let's talk about kids' clothes and what to wear to school. 
        </Paragraph>
        <Paragraph>
            Instead of realizing too late that your youngling doesn't have a fun dress for the summer or a pair of solid boots for the winter and scrambling to get the shopping done on time, perhaps it's best to do all (or most) of your clothes shopping before the new school year starts.
        </Paragraph>
        <Paragraph>
            That way, both you and your kid can take a breather knowing that they're all set until the next season. 
        </Paragraph>
        <Paragraph>
            But what exactly to get on this before-school shopping trip? What should a back to school clothing list include?
        </Paragraph>
        <Paragraph>
            Don't worry, we've got you. Here is a back to school clothes shopping list to help make the entire process easier and less stressful.
        </Paragraph>
        <Paragraph>
            <b>Notes:</b>
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>This is mainly meant for kindergarten, middle school and elementary school younglings.</UnorderedListItem>
            <UnorderedListItem>The suggested numbers of items are just that - suggestions. How many you actually buy depends on what school supplies and clothes your kid needs, your budget, and more.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Back to school basics
        </BlogHeading2>
        <Paragraph>
            First, your kid needs the back to school clothing essentials. Here is a unisex school list of kids clothing that you can pick and choose what you need from:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Underwear:</b> 7-10 pairs</UnorderedListItem>
            <UnorderedListItem><b>Bras:</b> 2-4</UnorderedListItem>
            <UnorderedListItem><b>Sports bras:</b> 2</UnorderedListItem>
            <UnorderedListItem><b>Tights and/or leggings:</b> 2-4</UnorderedListItem>
            <UnorderedListItem><b>Pajama sets:</b> 4</UnorderedListItem>
            <UnorderedListItem><b>Socks:</b> 7-10 pairs (think about the design of the socks, such as no show socks, ankle length, calf length, etc. Match them to the season and to your kid's preferences.)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Mix & match tops and bottoms
        </BlogHeading2>
        <Paragraph>
            We've seen some back to school clothes list examples that included a, frankly, dizzying number of tops and bottoms. 
        </Paragraph>
        <Paragraph>
            There is really no need for that. If planned right, with only 4 tops and 4 bottoms per season your kid could have 16 different outfits!
        </Paragraph>
        <Paragraph>
            Here's what to look for in tops and bottoms for a kids capsule wardrobe:
        </Paragraph>
        <BlogHeading3>
            Tops
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem><b>Pullover sweater:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Cardigan sweater:</b> 2-4</UnorderedListItem>
            <UnorderedListItem><b>T-shirts:</b> 2-4</UnorderedListItem>
            <UnorderedListItem><b>Long-sleeved shirts:</b> 2</UnorderedListItem>
            <UnorderedListItem><b>Sweatshirt/hoodie:</b> 1-2</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Bottoms
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem><b>Jeans:</b> 2 (go for light wash and dark wash for maximum variety)</UnorderedListItem>
            <UnorderedListItem><b>Pants:</b> 1-2 (think khakis, cargo pants, and other non denim options)</UnorderedListItem>
            <UnorderedListItem><b>Joggers and/or leggings:</b> 1-2</UnorderedListItem>
            <UnorderedListItem><b>Shorts:</b> 2</UnorderedListItem>
            <UnorderedListItem><b>Skirts:</b> 2</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Phew, if you bought all of these? Imagine the school outfit combos! Tee + cardigan + jeans; pullover + leggings; pullover + skirt; shorts + tee; sweatshirt + pants - your little one can mix and match for days.
        </Paragraph>
        <Paragraph>
            Oh and don't forget <b>dresses</b> if your kid is into that! 2 or 3 should do the trick on warmer weather days.
        </Paragraph>
        <BlockQuote>
            <b>Psst:</b> Add all of these items to a <InternalLink to="/#download-section">free shopping list app</InternalLink> so that you don't forget anything on shopping day!
        </BlockQuote>
        <BlogHeading2>
            Footwear
        </BlogHeading2>
        <Paragraph>
            What about school shoes? 
        </Paragraph>
        <Paragraph>
            Of course, the answer to this depends on what season it is and the climate in your area.
        </Paragraph>
        <Paragraph>
            Here are our suggestions for a continental climate region, where winters are cold and summers are hot:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Regular sneakers:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Fancier sneakers:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Winter boots:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Ballet flats:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Sandals:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Dress shoes:</b> 1</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            If your kid is still in the growing phase, it might not be the best idea to shop for next year's summer shoes in August. A lot can change, size-wise.
        </Paragraph>
        <Paragraph>
            That's why you could spread out your shoe shopping trips into two over the course of a year.
        </Paragraph>
        <BlogHeading2>
            Outerwear: winter is coming
        </BlogHeading2>
        <Paragraph>
            If you live in an area where winters are freezing (or most of the year is chilly), then your little student will need extra warmth and protection against the elements.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Thick winter jacket:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Lighter fall jacket:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Coat and/or denim jacket:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Gloves:</b> 2-4 pairs</UnorderedListItem>
            <UnorderedListItem><b>Winter hats:</b> 2-4</UnorderedListItem>
            <UnorderedListItem><b>Thick scarves:</b> 2-4</UnorderedListItem>
            <UnorderedListItem><b>Umbrella:</b> 1</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            What about fancy dress?
        </BlogHeading2>
        <Paragraph>
            It may so happen that your child has to attend a fancier school event. Some suggestions for such occasions include:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Button-up shirt:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Chinos:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Fancy dress:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Cute blouse:</b> 1</UnorderedListItem>
            <UnorderedListItem><b>Fancy skirt:</b> 1</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Of course, you don't don't have to get all of these new clothes. Make sure to buy what your young one finds both comfy and aesthetically pleasing.
        </Paragraph>
        <BlogHeading2>
            Back to school clothing checklist: printable
        </BlogHeading2>
        <Paragraph>
            Open the image in a new tab, save it to your computer, and print the school shopping list out whenever you want!
        </Paragraph>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/post-2022-14-back-to-school-clothes-shopping-printable.jpg" alt="Back to school clothing list printable"/>
        </ImageWithCaption>
        <BlogHeading2>
            Not a fan of printing?
        </BlogHeading2>
        <Paragraph>
            Here's how the back to school clothes shopping list looks like in our free app:
        </Paragraph>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/post-2022-14-Back-to-school-clothes-shopping-list-app.png" alt="Back to school clothes shopping list app"/>
        </ImageWithCaption>
        <Paragraph>
            <InternalLink to="/#download-section">Download it here </InternalLink>and make your back to school clothes list right now!
        </Paragraph>

    </StyledMainContent>
    </BlogPostWrapper>
}

export default BlogPost;